import { useEffect, useState } from "react";
import { Table, Col, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TiDocumentText } from "react-icons/ti";
import { HiDocumentText } from "react-icons/hi";
import { GoAlert } from "react-icons/go";
import { GrCircleAlert } from "react-icons/gr";

import { FaRegRegistered } from "react-icons/fa";

import Select from "react-select";
import { getSignedUrl, updateTask } from "../../../../services/axiosCall";
import "./TableCollapsible.css";
import { Origin, Pago, Taxes } from "../../../../utils/constants/constants";
import RolType from "../../../../constants/roles";
import PaymentState from "../../../../constants/paymentState";
import isCentralAppraisalRole from "../../../../utils/isCentralAppraisalRole";
import { errorToast } from "../../../../helpers/toastFunction";
import { useUserContext } from "contexts/UserContext";

const   TableCollapsible = ({
  tasks,
  locals,
  setExistingTask,
  handleShowDomain,
}) => {
  const navigate = useNavigate();
  const [update, setUpdate] = useState(null);
  const { user } = useUserContext()

  useEffect(() => {
    if (update?.id) {
      updateTask(update);
    }
  }, [update]);

  const updateDomainHandler = (data) => {
    if (isCentralAppraisalRole(user.roles)) {
      errorToast("No tienes permisos");
      return;
    }
    setExistingTask(data);
    handleShowDomain();
  };
  const updateStateHandler = (e, item) => {
    if (isCentralAppraisalRole(user.roles)) {
      errorToast("No tienes permisos");
      return;
    }
    setUpdate({ id: item.id, state: e.value });
  };
  const alertController = (item) => {
    if (item.incidencia) {
      return (
        <GoAlert
          size={26}
          color={item.incidencia.review ? "green" : "red"}
          title={`Incidencia asociada a la gestión #${item.id}`}
        />
      );
    } else if (
      item.pago?.id === Pago.COMPLETADO &&
      item.domain &&
      item.domain?.estadoPago !== PaymentState.COMPLETED
    ) {
      return (
        <FaRegRegistered
          size={26}
          color="red"
          title={`Reserva de dominio pendiente de pago`}
        />
      );
    } else {
      if (
        item.origin.id === Origin.EMPRESA_COLABORADORA &&
        item.pago?.id === Pago.COMPLETADO &&
        (!item.files.facturaVenta ||
          !item.files.fichaTecnica ||
          !item.files.circulacion)
      ) {
        return (
          <GrCircleAlert
            size={26}
            title="Falta documentación obligatoria por adjuntar."
          />
        );
      }
      return item.id;
    }
  };

  const totalImportHandler = (importe, ajuste, domain) => {
    const number = (importe - (ajuste || 0) - (domain || 0)).toFixed(2);
    let str = number.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, "."); //añade un punto cada 3 cifras
    return str.join(",");
  };

  const openDocumentHandler = async (data) => {
    const response = await getSignedUrl(data);
    window.open(response.signedUrl[0]);
  };

  const localHandler = (localId) => {
    if (locals) {
      const local = locals.find((item) => item.value === localId);
      return local?.label;
    }
  };

  const societyHandler = (societyId) => {
    if (user.companiesOptions) {
      const society = user.companiesOptions.find((item) => item.value === societyId);
      return society?.label;
    }
  };

  const checkAllDocumentation = (item) => {
    let response = false;
    if (item.origin.id === Origin.PARTICULAR) {
      if (
        item.dni &&
        item.contract &&
        item.gestoria &&
        item.circulacion &&
        item.fichaTecnica
      ) {
        response = true;
      }
    } else if (item.origin.id === Origin.AUTONOMO) {
      if (
        item.dni &&
        item.contract &&
        item.gestoria &&
        item.circulacion &&
        item.fichaTecnica &&
        item.iae &&
        item.facturaVenta
      ) {
        response = true;
      }
    } else {
      if (
        (item.dni &&
          item.contract &&
          item.gestoria &&
          item.circulacion &&
          item.fichaTecnica &&
          item.cif &&
          item.escritura &&
          item.facturaVenta) ||
        item.proforma
      ) {
        response = true;
      }
    }
    return response;
  };

  const stateOptionsHandler = (tramiteId, item) => {
    let options = [
      { label: "Pendiente", value: 1 },
      { label: "Listo", value: 2 },
      { label: "Listo GV", value: 3 },
      { label: "Carpeta", value: 4 },
      { label: "Enviado", value: 5 },
      { label: "Proforma", value: 6 },
      { label: "Notificado", value: 7 },
      { label: "Transferido", value: 8 },
      { label: "Facturado", value: 9 },
      { label: "Con cargas", value: 10 },
      { label: "Entregado", value: 11 },
      { label: "Rescindido", value: 12 },
      { label: "Transf./notif", value: 13 },
    ];

    const documents = checkAllDocumentation(item);
    const findProf = options.find((item) => item.value === 6);
    const findNot = options.find((item) => item.value === 7);
    const findTrans = options.find((item) => item.value === 8);
    const findTransfNot = options.find((item) => item.value === 13);
    let filterOption;

    if (!documents) {
      filterOption = options.filter(
        (elem) =>
          elem !== findNot &&
          elem !== findTrans &&
          elem !== findTransfNot &&
          elem !== findProf
      );
      return filterOption;
    } else {
      if (tramiteId === 1) {
        filterOption = options.filter(
          (elem) => elem !== findTrans && elem !== findTransfNot
        );
      } else if (tramiteId === 2) {
        filterOption = options.filter(
          (elem) => elem !== findNot && elem !== findTransfNot
        );
      } else if (tramiteId === 3) {
        filterOption = options.filter(
          (elem) => elem !== findNot && elem !== findTrans
        );
      }
      return filterOption;
    }
  };

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Tarea</th>
          <th>Fecha</th>
          <th>Gestión</th>
          <th>Trámite</th>
          {user.roles?.includes(RolType.CENTRAL_ADMIN || RolType.GENERAL_ADMIN) && (
            <th>Impuestos</th>
          )}
          <th>Origen</th>
          <th>Local</th>
          <th>Sociedad</th>
          <th>Titular</th>
          {user.roles?.includes(RolType.TRADING_AGENT) && <th>Identificación</th>}
          <th>Matrícula</th>
          {user.roles?.includes(RolType.TRADING_AGENT) && <th>Bastidor</th>}
          <th>Reserva Dominio</th>
          <th className="state_custom_width">Estado</th>
          <th className="importe_custom_width">Importe</th>
          <th>Pago</th>
        </tr>
      </thead>
      <tbody>
        {tasks &&
          (tasks?.length > 0
            ? tasks?.map((item) => {
                return (
                  <tr
                    id={item?.id}
                    key={item?.id}
                    data-toggle="collapse"
                    data-target=".multi-collapse1"
                    aria-controls="multiCollapseExample1"
                  >
                    <td
                      className="cursor_pointer"
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {alertController(item)}
                    </td>

                    <td
                      onClick={() => navigate(`/gestion/${item.id}`)}
                      className={`${
                        item.incidencia && "incidencia"
                      } cursor_pointer`}
                    >
                      {item.createdAt
                        ?.split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")}
                    </td>

                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {item.gestion.label}
                    </td>
                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {item.tramite.label}
                    </td>
                    {user.roles?.includes(
                      RolType.CENTRAL_ADMIN || RolType.GENERAL_ADMIN
                    ) && (
                      <td
                        className="cursor_pointer "
                        onClick={() => navigate(`/gestion/${item.id}`)}
                        title={
                          item.impuestos?.id === Taxes.IVA_INTRACOMUNITARIO &&
                          "Régimen Especial de Bienes usados en la venta de coches"
                        }
                      >
                        {item.impuestos.label}
                      </td>
                    )}
                    <td
                      className="cursor_pointer"
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {item.origin.label}
                    </td>
                    <td
                      className="cursor_pointer"
                      onClick={() => navigate(`/gestion/${item.id}`)}
                      title={item.society.nombre}
                    >
                      {localHandler(item.local)}
                    </td>
                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {societyHandler(item.society)}
                    </td>
                    <td
                      className="cursor_pointer"
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {item.titular}
                    </td>
                    {user.roles?.includes(RolType.TRADING_AGENT) && (
                      <td
                        className="cursor_pointer "
                        onClick={() => navigate(`/gestion/${item.id}`)}
                        title={item.titular}
                      >
                        {item.numeroIdentificacion}
                      </td>
                    )}
                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                      title={`${item.marca} ${item.modelo}`}
                    >
                      {item.matricula}
                    </td>
                    {user.roles?.includes(RolType.TRADING_AGENT) && (
                      <td
                        className="cursor_pointer "
                        onClick={() => navigate(`/gestion/${item.id}`)}
                      >
                        {item.bastidor}
                      </td>
                    )}
                    <td className="custom_domain_width">
                      {item.domain?.active ? (
                        <Row>
                          <Col>
                            <TiDocumentText
                              className="cursor_pointer"
                              onClick={() =>
                                openDocumentHandler(
                                  item.domain?.cartaFinanciera
                                )
                              }
                              title="Carta financiera"
                              size={26}
                              color={"#E88A00"}
                            />

                            {item.domain.informeTrafico ? (
                              <HiDocumentText
                                className="cursor_pointer"
                                onClick={() =>
                                  openDocumentHandler(
                                    item.domain?.informeTrafico
                                  )
                                }
                                title="Informe de tráfico"
                                size={26}
                                color={"#E88A00"}
                              />
                            ) : (
                              <HiDocumentText
                                className=""
                                title="Informe de tráfico no disponible"
                                color={"#545454"}
                                size={26}
                              />
                            )}
                          </Col>
                          <Col>
                            <p
                              onClick={() => navigate(`/gestion/${item.id}`)}
                              title={
                                item.domain.estadoPago ===
                                PaymentState.UNPROCESSED
                                  ? "Fecha límite para el pago de la reserva de dominio."
                                  : item.domain.estadoPago ===
                                    PaymentState.SIGNATURE_PENDING
                                  ? "El pago se encuentra en proceso de firma."
                                  : item.domain.estadoPago ===
                                    PaymentState.COMPLETED
                                  ? `Pagado el día ${item.domain.fechaPago}`
                                  : `Vencimiento fecha pago: ${item.domain.fechaLimite
                                      ?.split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join("/")}`
                              }
                              className={`custom__width__date my-1 cursor_pointer`}
                            >
                              {item.domain.estadoPago === PaymentState.COMPLETED
                                ? "Pagado"
                                : item.domain.fechaLimite
                                    ?.split("T")[0]
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                            </p>
                          </Col>
                        </Row>
                      ) : item.pago?.id === Pago.SIN_TRAMITAR || !item.pago ? (
                        <Button
                          className="btn_custom mx-5"
                          title="La reserva de dominio es opcional y se puede añadir más tarde."
                          onClick={() => updateDomainHandler(item)}
                          disabled={item.incidencia}
                        >
                          Añadir
                        </Button>
                      ) : (
                        <p
                          className="cursor_pointer"
                          onClick={() => navigate(`/gestion/${item.id}`)}
                        >
                          No
                        </p>
                      )}
                    </td>
                    <td>
                      <Select
                        id={item.id}
                        isDisabled={
                          item.incidencia
                            ? true
                            : item.pago?.id === Pago.SIN_TRAMITAR ||
                              item.pago === null
                            ? false
                            : true
                        }
                        placeholder="Seleccione estado"
                        value={{
                          label: item.state.label,
                          value: item.state.id,
                        }}
                        onChange={(e) => updateStateHandler(e, item)}
                        options={stateOptionsHandler(item.tramite.id, item)}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#fe9800",
                            primary25: "#ffc548",
                          },
                        })}
                      />
                    </td>
                    <td
                      className="cursor_pointer custom_import_width"
                      onClick={() => navigate(`/gestion/${item.id}`)}
                      title="Cantidad total a pagar"
                    >
                      {totalImportHandler(
                        item.importe,
                        item.ajuste,
                        item.domain?.importe
                      )}{" "}
                      €
                    </td>

                    <td
                      className="cursor_pointer custom_payment_width"
                      onClick={() => navigate(`/gestion/${item.id}`)}
                      title={item.fechaPago}
                    >
                      {item.pago ? item.pago.label : ""}
                    </td>
                  </tr>
                );
              })
            : "No existen resultados que correspondan con la búsqueda.")}
      </tbody>
    </Table>
  );
};

export default TableCollapsible;
